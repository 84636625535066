.sider-logo {
  height: 32px;
  margin: 16px;
  cursor: pointer;
  text-align: center;
}

.sider-logo img {
  height: 32px;
}

@hack: true; @import "/app/src/theme.less";