.header-logo {
  float: left;
  height: 32px;
  margin: 16px 24px;
  cursor: pointer;
}

.ant-row-rtl .header-logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.landing-header-menu-xs {
  display: none;
}

.landing-header-menu-xs {
  float: right;
  padding: 12px!important;
  background: white;
  border: 1px solid @border-color;
  border-radius: @border-radius-base;
  margin: 12px 12px 0px 0px;
}

@media (max-width: @screen-xs-max) {
  .landing-header-menu-xs {
    display: block;
  }
  .landing-header-menu {
    display: none;
  }
}

@hack: true; @import "/app/src/theme.less";