@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Varela&display=swap');

h1 {
    @apply text-2xl;
}
h2 {
    @apply text-xl;
}
h3 {
    @apply text-lg;
}
