.intro-container {
  width: 100%;
  position: relative;
}

.hero-img {
  display: block;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.hero-img-smaller {
  display: none;
  width: 100%;
}

.hero-img-smallest {
  display: none;
  width: 100%;
}

.hero-img-tiny {
  display: none;
  width: 100%;
}

@media (max-width: @screen-lg-max) {
  .hero-img {
    display: none !important;
  }
  
  .hero-img-smaller {
    display: block !important;
  }
  
  .hero-img-smallest {
    display: none !important;
  }
  
  .hero-img-tiny {
    display: none !important;
  }
}

@media (max-width: @screen-sm-max) {
  .hero-img {
    display: none !important;
  }
  
  .hero-img-smaller {
    display: none !important;
  }
  
  .hero-img-smallest {
    display: block !important;
  }
  
  .hero-img-tiny {
    display: none !important;
  }
}

@media (max-width: 550px) {
  .hero-img {
    display: none !important;
  }
  
  .hero-img-smaller {
    display: none !important;
  }
  
  .hero-img-smallest {
    display: none !important;
  }
  
  .hero-img-tiny {
    display: block !important;
  }
}

.intro-text-container {
  position: absolute;
  top: 30%;
  left: 0px;
  right: 0px;
  text-align: right;
  padding-left: 20px;
}

.intro-text-container-sm {
  text-align: center;
  padding-left: 10px;
}

.intro-text-headline-primary {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 0px;
}
.intro-text-headline {
  font-size: 2.1em;
  margin-bottom: 10px;
}

.intro-text-headline-primary {
  color: @primary-color;
}

@media (max-width: @screen-md-max) {
  .intro-text-headline-primary {
    font-size: 2.3em;
  }
  .intro-text-headline {
    font-size: 1.9em;  }
}

@media (max-width: @screen-sm-max) {
  .intro-text-container-sm {
    padding: 10px;
  }
}

.intro-text-description {
  margin-bottom: 10px;
}

.intro-container .ant-btn {
  margin-top: 10px;
}

// .get-started-btn:hover {
//   background: white !important;
//   color: @primary-color !important;
// }

.landing-page-container {
    width: 100%;
    align-self: center;
    margin: auto;
}

.make-container(@minWidth, @breakpoint) {
    @media (min-width: @minWidth) {
        .landing-page-container {
            max-width: @breakpoint;
        }
    }
}

.make-container(@screen-xs-min, @screen-xs);
.make-container(@screen-sm-min, @screen-sm);
.make-container(@screen-md-min, @screen-md);
.make-container(@screen-lg-min, @screen-lg);
.make-container(@screen-xl-min, @screen-xl);

.landing-page-section-title {
  width: 100%;
  margin: 30px 0px;
  color: grey;
  text-align: center;
  font-size: 1.4em;
}

.landing-page-section-sub-title {
  &:extend(.landing-page-section-title);
  font-size: 1.2em;
  margin: 15px 0px;
}

.feature-highlight-text-container {
  padding: 40px;
}

@media (max-width: @screen-xs-max) {
  .feature-highlight-text-container {
    padding: 20px;
  }
}

.feature-highlight-text-left {
  text-align: right;
}

.feature-highlight-text-right {
  text-align: left;
}

.feature-highlight-title {
  font-size: 2em;
}

.secondary-feature-icon {
  font-size: 35px;
  color: @primary-color;
}

.secondary-feature-container {
  text-align: center;
  transition: transform .3s;
}

.secondary-feature-container:hover {
  transform: scale(1.08);
  background: @primary-color;
  color: white;
}

.secondary-feature-container:hover .secondary-feature-icon {
  color: white;
}

@media (max-width: @screen-sm-max) {
  .feature-highlight-text-left {
    text-align: center;
  }

  .feature-highlight-text-right {
    text-align: center;
  }

  .feature-highlight-title {
    font-size: 1.5em;
  }
}

.feature-highlight-img-container {
  padding: 20px;
  margin: auto;
}

.feature-highlight-img-container-right {
  text-align: right;
}

@media (max-width: @screen-sm-max) {
  .feature-highlight-img-container,
  .feature-highlight-img-container-right {
    text-align: center;
  }
}

.feature-highlight-img {
  width: 100%;
  max-width: 320px;
}

@media (max-width: @screen-sm-max) {
  .feature-highlight-img {
    max-width: 280px;
  }
}

.landing-page-image-attribute-link {
  display: none;
  position: absolute;
  bottom: 12px;
  left: 0px;
  right: 0px;
  color: #aaa;
  font-size: @font-size-sm;
  opacity: 0.3;
  text-align: center;
}

@media (max-width: @screen-lg-max) {
  .landing-page-image-attribute-link {
    bottom: 24px;
  }
}

@media (max-width: @screen-xs-max) {
  .landing-page-image-attribute-link {
    bottom: 24px;
  }
}

@media (max-width: 550px)  {
  .landing-page-image-attribute-link {
    bottom: 0px;
  }
}

.landing-top-row {
  padding: 20px 0px;
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
}

.landing-page-score-card {
  display: inline-block;
  width: 90px;
  border-width: 2px;
  padding: 6px 0px;
}

.landing-page-score-card-left {
  transform: rotate(-20deg) translate(10px, 25px);
}

.landing-page-score-card-center {
  background: #68C1C7;
  border-color: #68C1C7;
  color: white;
}

.landing-page-score-card-right {
  transform: rotate(20deg) translate(-10px, 25px);
  background: @primary-color;
  color: white;
}

.landing-voting-score-card-number {
  font-size: 2.5em;
}

@hack: true; @import "/app/src/theme.less";