.pricing-card {
  text-align: center;
}

.pricing-tier-details {
  width: 100%;
  text-align: center;
}

.pricing-tier-details button:hover {
  color: @primary-color;
  background: white;
}

.pricing-lowest-plan .ant-card-head {
  background: #f8d3f6;
}

.pricing-medium-plan .ant-card-head {
  background: #dd22d0;
  color: white;
}

.pricing-highest-plan .ant-card-head {
  background: @primary-color;
  color: white;
}

.landing-page-container .ant-card-head-title {
  text-align: center;
}

.pricing-tier-monetary-value {
  font-size: 1.7em;
}

.pricing-tier-monetary-value-strikethrough {
  font-size: 1.7em;
  text-decoration: line-through;
  color: #aaa;
}

.pricing-tier-details-value {
  color: @primary-color;
}

@media (min-width: @screen-sm-min) {
  .pricing-tier-details-comming-soon-container {
    height: 285px;
  }
}

@hack: true; @import "/app/src/theme.less";