.todo-action {
  padding-left: 16px;
  padding-right: 16px;
}

.todo-action-delete {
  color: @red-8;
}

.todo-card {
  box-shadow: 0 1px 4px 0 rgba(21, 27,38, 0.1);
}

.todo-card-clickable {
  cursor: pointer;
}

.todo-card .ant-comment-inner {
  margin-top: 0.5em;
}

.todo-card .ant-comment-actions {
  margin-top: 6px;
}

.todo-card p {
  margin-bottom: 0.8em;
}

.todo-card-horizontal .ant-card-body {
  padding-top: 10px;
  padding-bottom: 4px;
}

.todo-comment-section-container p {
  margin-bottom: 6px;
}

.todo-card-action-btn {
  position: absolute;
  top: 20px;
  right: 18px;
}

.todo-card .todo-title {
  margin-right: 30px;
}

.todo-drawer-field-row {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
}

.todo-drawer-field-name {
  width: 100px;
}

.todo-drawer-field-content {
  flex: 1;
}

.todo-drawer .ant-comment-inner {
  padding: 0;
}

.todo-drawer .ant-drawer-body {
  position: relative;
}

.todo-drawer .ant-comment-actions {
  margin-top: 0px;
}

.todo-drawer-action-btn {
  position: absolute;
  top: 26px;
  right: 24px;
}

.todo-created-timestamp {
  font-size: 0.9em;
  margin-top: 0.1em;
  color: #777;
}

.todo-drawer-archived .ant-drawer-content,
.todo-drawer-archived .ant-drawer-header {
  background: @teamkit-hover-bg;
}

.todo-display-title h2 {
  font-size: 22px;
  padding-right: 30px;
}

.todo-label-tag {
  color: #555;
}

.todo-col-droppable .todo-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.todo-filter-popover {
  min-width: 400px;
  padding: 12px;
}

.todo-menu-item {
  min-width: 200px;
}
@hack: true; @import "/app/src/theme.less";