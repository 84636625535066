.create-first-goal-card {
    max-width: 800px;
    margin: auto;
    margin-top: 1em;
}

.create-first-goal-bg-image-container img {
    width: 100%;
}

.goal-group-goals-container {
    max-width: 1000px;
    margin: auto;
    margin-top: 1em;
}

.goal-create-form-container {
    max-width: 1000px;
    margin: auto;
}

.goal-group-goals-list-action-row {
    margin-bottom: 1em;
}

.remove-target-button {
    position: absolute;
    top: -12px;
    right: -12px;
}

.goal-progress-container {
    margin-left: 1em;
}

.target-description-text {
    color: @text-color-secondary;
}

.goal-progress-bar-no-target {
    font-size: 0.7em!important;
}

.goal-card-no-target-text {
    color: @text-color-secondary;
}

.goal-details-edit {
    position: absolute;
    right: 1em;
    color: #999;
}

.goal-detail-card .goal-period {
    font-size: 0.9em;
}

.goal-detail-card .ant-card-head-title {
    display: block;
    white-space: normal;
}

.goal-detail-card .goal-progress-bar {
    padding-right: 3.5em;
}

.goal-comment-form-cards .ant-card-body {
    padding: 0 1em 0 0;
}

.goal-comment-cards .ant-card-body {
    padding: 0px 18px;
}

.goal-show-target-card-title {
    margin-right: 42px;
}

@media (max-width: @screen-md-max) {
    .goal-show-comments-container {
        margin-top: 0!important;
    }
}

.target-status-update .ant-tag {
    margin-right: 0;
}

.goal-comment-timeline-timestamp {
    font-size: 0.8em;
    color: @text-color-secondary;
}

.target-update-update-progress-text {
    color: @text-color-secondary;
}

.target-update-update-progress-text .ant-btn {
    padding: 0;
    font-style: italic;
}

.goal-comment-cards .ant-card-head-title {
    font-size: @font-size-base;
    display: block;
    overflow: initial;
    white-space: break-spaces;
}

@hack: true; @import "/app/src/theme.less";