.page-breadcrum {
  margin-bottom: 12px;
}

.create-board-bg-image-container {
  text-align: center;
}

.card-image-attribute-link {
  position: absolute;
  bottom: 8px;
  right: 16px;
  color: #ccc;
  font-size: 13px;
}

.create-board-bg-image {
  width: 100%;
  max-width: 400px;
}

.voting-score-card {
  text-align: center;
  border: 1px solid @primary-color;
  color: @primary-color;
}

.voting-score-card-number {
  font-size: 4em;
}

.voting-score-card-clickable {
  transition: transform .3s;
}

.voting-score-card-clickable:hover {
  z-index: 10;
  transform: scale(1.15);
  cursor: pointer;
}

.voting-score-card-inactive {
  opacity: 0.3;
}

.voting-score-card-active {
  z-index: 10;
  transform: scale(1.1) translate(0px,-4px);
  background: @primary-color;
  color: white;
}

.voting-score-card-active .ant-card-body{
  padding: 10px 18px;
}

.voting-score-card-result {
  background: @primary-color;
  color: white;
}

.vote-confirm-btn {
  border-color: white;
}

.vote-card-btn {
  font-size: 1em;
}

.voting-item-panel-header {
  display: flex;
  background-color: white;
  border-radius: @border-radius-base;
  padding: 12px;
}

.voting-item-panel-header .voting-item-title {
  flex: 1;
  font-size: 1.1em;
  margin-top: 4px;
}

.voting-result-row {
  display: flex;
  background-color: white;
  border-radius: @border-radius-base;
}

.voting-result-names {
  flex: 1;
  padding: 12px;
}

.voting-result-score {
  padding: 12px;
  border-left: 1px solid #e0e0e0;
  min-width: 60px;
  background-color: #fafafa;
  margin: auto;
  text-align: center;
}

.voting-panel-member-status-row {
  margin-top: 12px;
  max-width: 100%;
  overflow-x: auto;
}

.voting-status-card-username {
  flex: 1;
  margin: 3px 0px 0px 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.voting-status-card {
  max-width: 120px;
}

.voting-status-card-not-online {
  color: #999;
  background-color: #f6f6f6;
}

.voting-status-card-voted {
  background: #d1f1cc;
  border-color: green;
}

.voting-status-card .ant-card-body {
  padding: 12px;
}

.voting-status-card .ant-badge-dot {
  width: 10px;
  height: 10px;
  background-color: #1db31d;
  top: 3px;
  right: 2px;
}

.voting-item-form {
  width: 100%;
  display: flex;
}

.voting-item-title-input {
  flex: 1;
  margin: 0px 10px 0px 0px;
}

.coffee-break-modal {
  max-width: 250px;
}

.coffee-break-modal .ant-modal-body {
  padding: 0;
}

.coffee-break-modal .ant-modal-footer {
  display: none;
}

.coffee-break-card {
  width: 100%;
  max-width: 250px;
  margin: auto;
  padding: 50px 0px 35px;
  top: 10%;
}

.coffee-break-card .anticon {
  font-size: 8em;
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotateY(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotateY(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotateY(360deg); transform:rotateY(360deg); } }

.coffee-break-card .username-text {
  font-size: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.voting-session-show-control-panel {
  display: flex;
  margin-bottom: 10px;
}

.voting-session-show-control-panel button {
  margin-left: 6px;
}

@hack: true; @import "/app/src/theme.less";