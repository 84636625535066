@import (css) url('https://fonts.googleapis.com/css2?family=Varela&display=swap');

p {
  overflow-wrap: break-word;
}

.ant-layout {
  min-height: 100vh;
}

.ant-layout-header {
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.08);
  z-index: 10;
}

.ant-menu-horizontal {
  border-bottom: none;
}

.site-content-inner {
  padding: 8px;
  width: 100%;
}
@media (max-width: @screen-xs-max) {
  .site-content-inner  {
    padding: 8px;
  }
}

.site-content {
  margin: 8px;
}
@media (max-width: @screen-xs-max) {
  .site-content  {
    margin: 8px 0px;
  }
}

.column-card-title {
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: @geekblue-8;
}

.column-card-positive {
  background-color: @green-8!important;
}

.column-card-positive-1 {
  background-color: @cyan-8!important;
}

.column-card-neutral {
  background-color: @geekblue-8!important;
}

.column-card-neutral-1 {
  background-color: @primary-color!important;
}

.column-card-negative {
  background-color: @red-8!important;
}

.column-card-negative-1 {
  background-color: #ff6600!important;
}

.column-card-white {
  background-color: white!important;
  color: black!important;
}

.column-card-grey {
  background-color: grey!important;
  color: white;
}

.column-card-title .ant-card-body {
  padding: 10px 18px;
}

.column-card-form .ant-card-body {
  padding: 24px 24px 0px;
}

.column-card-form.ant-card-loading .ant-card-body {
  padding: 24px !important;
}

.avatar-1 {
  color: #f56a00;
  background-color: #fde3cf;
}

.avatar-2 {
  color: @purple-6;
  background-color: @purple-2;
}

.avatar-3 {
  color: @green-6;
  background-color: @green-2;
}

.avatar-4 {
  color: @cyan-6;
  background-color: @cyan-2;
}

.avatar-5 {
  color: @gold-6;
  background-color: @gold-2;
}

.avatar-6 {
  color: @volcano-6;
  background-color: @volcano-2;
}

.avatar-7 {
  color: @magenta-6;
  background-color: @magenta-2;
}

.avatar-8 {
  color: @lime-6;
  background-color: @lime-2;
}

.avatar-unassigned-clickable {
  background: none;
  color: #9AA4AD;
  border: 1px dashed #9AA4AD;
}

.container {
    width: 100%;
    align-self: center;
    margin: auto;
}

.make-container(@minWidth, @breakpoint) {
    @media (min-width: @minWidth) {
        .container {
            max-width: @breakpoint;
        }
    }
}

// .make-container(@screen-xs-min, @screen-xs);
// .make-container(@screen-sm-min, @screen-sm);
// .make-container(@screen-md-min, @screen-md);
// .make-container(@screen-lg-min, @screen-lg);
// .make-container(@screen-xl-min, @screen-xl);
.make-container(@screen-xxl-min, @screen-xxl); // Optional

.static-page-container {
    width: 100%;
    align-self: center;
    margin: auto;
}

.make-static-page-container(@minWidth, @breakpoint) {
    @media (min-width: @minWidth) {
        .static-page-container {
            max-width: @breakpoint;
        }
    }
}

// .make-static-page-container(@screen-xs-min, @screen-xs);
// .make-static-page-container(@screen-sm-min, @screen-sm);
// .make-static-page-container(@screen-md-min, @screen-md);
.make-static-page-container(@screen-lg-min, @screen-lg);
.make-static-page-container(@screen-xl-min, @screen-xl);
// .make-static-page-container(@screen-xxl-min, @screen-md); // Optional

.ant-card-actions > li:hover {
  background-color: @teamkit-hover-bg;
}

// fix ant-card radius bug
.ant-card-actions {
  border-bottom-left-radius: @border-radius-base;
  border-bottom-right-radius: @border-radius-base;
}

.teamkit-section-title {
  color: #777;
  font-weight: bold;
  padding: 8px;
}

.teamkit-align-center {
  text-align: center;
}

.teamkit-margin-auto {
  margin: auto;
}


.teamkit-show-top-detail-card:hover {
  border-color: @primary-color;
  cursor: pointer;
}

.teamkit-show-top-detail-card h2,
.teamkit-show-top-detail-card-no-edit h2 {
  margin: 0;
  display: inline-block;
}

.teamkit-show-top-detail-card .anticon-edit,
.teamkit-show-top-detail-card-no-edit .anticon-edit {
  display: none;
}
.teamkit-show-top-detail-card-smaller .anticon-edit {
  margin-top: -6px;
}

.teamkit-show-top-detail-card:hover .anticon-edit {
  display: block;
}

.teamkit-show-top-detail-card-smaller .ant-card-body {
  padding: 8px 16px 0px;
}

.teamkit-small-listing-card {
  cursor: pointer;
}

.teamkit-small-listing-card:hover {
  border-color: @primary-color;
}

.teamkit-small-listing-card-summary {
  font-size: 80%;
  color: #999;
}

.teamkit-card-floating-button {
  position: absolute;
  top: @card-padding-base;
  right: @card-padding-base;
}

.teamkit-display-flex {
  display:flex;
}

.teamkit-flex-full {
  flex:1;
}

.teamkit-float-right {
  float: right;
}

.teamkit-align-item-center {
  align-items: center;
}

.static-page-content-section {
  background: white;
  padding: 16px 24px;
}

.teamkit-comment .ant-comment-nested {
  margin-left: 0;
}

.teamkit-comment .ant-comment-actions {
  margin-top: 6px;
}

.teamkit-clickable {
  cursor: pointer;
}

.comment-reply-link {
  font-size: @font-size-sm;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
}

.ant-comment-inner .ant-form-item {
  margin-bottom: 16px;
}

.item-comment {
  background: #f2f2f2;
  padding: 6px 12px;
  border-radius: @border-radius-base;
}

.item-comment-form {
  padding-left: 14px;
}

.item-comment .ant-comment-content {
  margin-top: 0.2em;
}

.teamkit-create-card-container {
  display: flex;
}

.teamkit-create-card {
  background-color: @primary-color;
  color: white;
  text-align: center;
  flex: 1;
}

.teamkit-create-card-help {
  padding: 6px;
  margin: auto;
  font-size: 18px;
}

.teamkit-no-margin-bottom {
  margin-bottom: 0px!important;
}

.teamkit-show-line-break {
  white-space: pre-line;
}

.teamkit-top-action-bar {
  display: flex;
  justify-content: flex-end;
}

.teamkit-margin-auto {
  margin: auto;
}

.teamkit-control-panel {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

@hack: true; @import "/app/src/theme.less";