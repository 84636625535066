.kudo-mini-card,
.kudo-card {
    margin-top: 32px;
}

.kudo-mini-card .ant-card-body {
    padding-top: 38px;
}

.kudo-card .ant-card-body {
    padding: 12px 24px;
}

.kudo-mini-avatar-container {
    position: absolute;
    top: -32px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
}

.kudo-card-menu-button {
    z-index: 1;
}

.kudo-notification-modal .ant-modal-content {
    background: none;
    box-shadow: none;
}

.kudo-notification-modal-close-btn {
    padding: 10px 10px 6px;
    background: white;
    border-radius: 8px;
    margin-top: -16px;
    margin-right: 28px;
}

.kudo-list-receiver-card {
    margin-top: 52px;
}

.kudo-list-receiver-card .ant-card-body {
    padding-top: 70px;
}

.kudo-list-receiver-avatar-container {
    position: absolute;
    top: -64px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
}

.kudo-list-filter-label {
    margin: auto;
    margin-right: 1em;
}

@hack: true; @import "/app/src/theme.less";