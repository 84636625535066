.landing-feature-img {
  cursor: pointer;
}

.howto-img-container {
  text-align: center;
}

.howto-img-container img {
  width: 100%;
  max-width: 700px;
}

.howto-img-container img.img-small {
  width: 100%;
  max-width: 300px;
}

@hack: true; @import "/app/src/theme.less";