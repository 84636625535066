.promo-banner-top {
  position: relative;
  padding: 12px 48px;
  background: #E3FEE3;
}

.promo-banner-top .close-icon {
  position: absolute;
  top: 10px;
  right: 14px;
  padding: 10px;
}

@hack: true; @import "/app/src/theme.less";