.ant-layout-header {
  background: white;
}

.ant-layout-header .ant-dropdown-trigger {
  cursor: pointer;
  padding: 0px 30px;
}

.ant-layout-header .ant-dropdown-trigger:hover {
  cursor: pointer;
  padding: 0px 30px;
  background-color: @teamkit-hover-bg;
}

.ant-layout-header .profile-actions-dropdown {
  float: right;
}

.app-header {
  display: flex;
}

.app-header .header-logo-app-mode {
  display: none;
}

.app-header .header-main-bar {
  flex: 1;
}

@media (max-width: @side-menu-drawer-threshold) {
  .app-header .header-logo-app-mode {
    display: block;
  }
}

@media (max-width: @screen-xs-max) {
  .ant-layout-header .profile-user-name {
    display: none;
  }
}

.header-lite-text {
  color: @primary-color;
  font-weight: bold;
  min-width: 100px;
}

@hack: true; @import "/app/src/theme.less";