.item-card .ant-card-head {
  background-color: #fcfcfc;
}

.item-card .ant-card-head-title,
.item-card .ant-card-extra {
  padding: 8px 0px;
}

.item-card .ant-comment-inner {
  padding: 0px;
}

.create-board-bg-image-container {
  text-align: center;
}

.card-image-attribute-link {
  position: absolute;
  bottom: 8px;
  right: 16px;
  color: #ccc;
  font-size: 13px;
}

.create-board-bg-image {
  width: 100%;
  max-width: 400px;
}

.col-navigation-button {
  margin-top: 5px;
  height: 47px;
  padding: 6px;
}

.col-navigation-button-left {
  margin-right: 5px;
}

.col-navigation-button-right {
  margin-left: 5px;
}

.ant-carousel .slick-dots {
  height: 25px;
}

.ant-carousel .slick-dots li button {
  height: 24px;
  background: #fff;
  opacity: 1;
  font-size: 14px;
  color: black;
  border-radius: 12px;
}

.board-pagination-button {
  height: 24px;
  width: 24px;
  font-size: 14px;
  border-radius: 12px;
  padding: 0;
}

.ant-carousel .slick-dots li.slick-active button {
  background: @primary-color;
  color: white;
}

.custom-column-row {
  margin-left: 16px;
  width: 100%;
}

.column-card-title-editable {
  cursor: pointer;
}

.column-card-title-editable .anticon {
  display: none;
}

.column-card-title-editable:hover .anticon {
  display: inline-block;
}

.public-mode-info-card {
  text-align: center;
}

.public-mode-info-card .ant-card-body {
  padding: 4px 24px;
}

@hack: true; @import "/app/src/theme.less";