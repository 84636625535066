.item-form-submit-button {
  margin-right: 10px;
}

.item-card {
  position: relative;
}

.item-card > .ant-card-body {
  padding-left: 28px;
  padding-bottom: 10px;
}

.item-card-menu-button {
  position: absolute;
  top: 12px;
  right: 18px;
}

@media (min-width: @screen-xxl-min) {
  .item-form-inline-action-container {
    display: flex;
  }

  .item-form-inline-action-container .anonymous-selector {
    flex: 1;
  }
  .item-form-inline-action-container .item-form-submit-button {
    margin-right: 0px;
    margin-left: 10px;
  }
}

.item-card-drag-span {
  padding: 0.5em;
  position: absolute;
  top: 4px;
  left: -5px;
  font-size: 1.5em;
  color: grey;
}

.board-kudo-container-card {
  width: 100%;
  margin-bottom: 8px;
}

.board-kudo-container-card .ant-collapse-content {
  background-color: @background-color!important;
}

.board-kudo-container-card > .ant-card-body {
  padding: 0px;
}

@hack: true; @import "/app/src/theme.less";