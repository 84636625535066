@import '../antd-legacy.less';

.column-card-title {
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: @geekblue-8;
}

.filter-count-badge {
  position: absolute;
  top: -8px;
  right: -6px;
}

.todo-lane-header {
  border-bottom: 4px solid @primary-color;
  padding: 4px 0px 16px;
  text-transform: uppercase;
  font-size: 0.86em;
}

.todo-lane-header-not-started {
  border-color: @lime-6;
}

.todo-lane-header-in-progress {
  border-color: @blue-8;
}

.todo-lane-header-done {
  border-color: @green-7;
}

.todo-lane-header-canceled {
  border-color: @red-7;
}

.todo-col-empty-card {
  background: none;
  border: none;
}

@hack: true; @import "/app/src/theme.less";