.site-content-x-scrollable {
    overflow-x: auto;
    padding: 16px;
}
button, [type='button'], [type='reset'], [type='submit'] {
    background-color: white;
}

.ant-btn-primary {
    background-color: @primary-color;
}

@hack: true; @import "/app/src/theme.less";