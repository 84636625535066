.blockers-container .ant-form-item{
    margin-bottom: 0.5em;
}

.checkin-item-blocker-done {
    background: #f6f6f6;
    color: grey;
    font-style: italic;
}

.active-checkin-group-select-card {
    background: #ffebfe;
    border-color: @primary-color;
}

.checkin-group-select-card .ant-card-body {
    padding-bottom: 0px;
    padding-top: 1em;
}

.checkin-group-select-card-minimized .ant-card-body {
    padding-bottom: 5px;
}

.checkin-session-comment-form {
    padding-bottom: 0;
}

.single-checkin-item-cards {
    padding: 8px;
}

.single-checkin-item-cards .ant-card-body {
    padding: 0 1em 0 0;
}

@media (max-width: @screen-md-max) {
    .checkin-group-select-card .ant-card-body {
        padding: 12px;
    }
}

@hack: true; @import "/app/src/theme.less";