.team-select {
  border-left: 1px solid @border-color;
  overflow: hidden;
}

.team-select div {
  height: @layout-header-height;
}

@media (max-width: @screen-xs-max) {
  .ant-layout-header .team-select {
    padding: 0px 15px!important;
  }
}

@hack: true; @import "/app/src/theme.less";