
.user-profile-form  {
    text-align: -webkit-center;
}

.user-profile-form .ant-form-item {
    max-width: 600px;
}

.user-profile-form .ant-select-selector {
    text-align: left;
}

.profile-page-avatar-container {
    position: relative;
    cursor: pointer;
}

.profile-page-avatar-edit {
    display: none;
    position: absolute;
    bottom: -32px;
    left: 0;
    right: 0;
    background-color: black;
    color: white;
}

.profile-page-avatar-container:hover > .profile-page-avatar-edit {
    display: block;
}
@hack: true; @import "/app/src/theme.less";