.poll-display-card .ant-radio-wrapper-disabled {
    color: @primary-text-color;
}

.edit-response-link {
    display: block;
    margin: auto;
    margin-top: 1em;
}


.poll-display-card .ant-checkbox-group {
    display: block;
}

@hack: true; @import "/app/src/theme.less";