.todo-no-content-text {
  font-style: italic;
}

.todo-editable-content {
  cursor: pointer;
}

.todo-editable-content .anticon {
  display: none;
}

.todo-editable-content:hover .anticon {
  display: inline-block;
}

.todo-editable-content .status-tag {
  cursor: pointer;
}

@hack: true; @import "/app/src/theme.less";